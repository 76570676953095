// Variables
$enable-rounded: true !default;
$enable-rfs: true !default;
$enable-antialiasing: true !default;
$enable-negative-margins: true !default;

$border-width: 1px !default;
$border-color: #DFDFDF !default;
$border-radius: 1rem !default;
$border-radius-sm: 0.25rem !default;
$border-radius-lg: 1.5rem !default;

$min-contrast-ratio: 2 !default;
$body-color: #232126 !default;
$link-color: #5B2FE2 !default;
$text-muted: #9B96A4 !default;
$headings-color: #232126 !default;

$white: #fff !default;
$black: #000 !default;

$gray-100: #F8F8F8 !default;
$gray-200: #DFDFDF !default;
$gray-300: #C5C5C5 !default;
$gray-400: #ABABAB !default;
$gray-500: #9B96A4 !default;
$gray-600: #787878 !default;
$gray-700: #5F5F5F !default;
$gray-800: #232126 !default;
$gray-900: #101010 !default;

$primary: #5B2FE2 !default;
$secondary: #9B96A4 !default;
$success: #17C9A9 !default;
$info: #BEE0FB !default;
$warning: #ffc398 !default;
$danger: #FFCBC0 !default;
$light: #F8F8F8 !default;
$dark: #101010 !default;

$primary-light: #F4F0FF !default;
$secondary-light: #F8F8F8 !default;
$success-light: #83FF8F !default;
$info-light: #EEF7FE !default;
$warning-light: #FFF9F0 !default;
$danger-light: #FFF5F3 !default;
$light-light: #FEFEFE !default;
$dark-light: #232126 !default;

$primary-dark: #AA8AFF !default;
$secondary-dark: #C5C5C5 !default;
$success-dark: #8DF9C9 !default;
$info-dark: #8DC9F9 !default;
$warning-dark: #FFD08A !default;
$danger-dark: #FFA18D !default;
$light-dark: #C5C5C5 !default;

$theme-colors: (
    primary: $primary,
    secondary: $secondary,
    success: $success,
    info: $info,
    warning: $warning,
    danger: $danger,
    light: $light,
    dark: $dark,
    primary-light: $primary-light,
    secondary-light: $secondary-light,
    success-light: $success-light,
    info-light: $info-light,
    warning-light: $warning-light,
    danger-light: $danger-light,
    light-light: $light-light,
    dark-light: $dark-light,
    primary-dark: $primary-dark,
    secondary-dark: $secondary-dark,
    success-dark: $success-dark,
    info-dark: $info-dark,
    warning-dark: $warning-dark,
    danger-dark: $danger-dark,
    light-dark: $light-dark
) !default;

$blue: #2D9CF3 !default;
$red: #FF4C27 !default;
$orange: #FFA724 !default;
$green: #2DF39C !default;

$blue-100: #EEF7FE !default;
$blue-200: #BEE0FB !default;
$blue-300: #8DC9F9 !default;
$blue-400: #5DB3F6 !default;
$blue-500: #2D9CF3 !default;
$blue-600: #0D83E0 !default;
$blue-700: #0A67B0 !default;
$blue-800: #074B80 !default;
$blue-900: #EEF7FE !default;

$indigo-100: #F4F0FF !default;
$indigo-200: #CFBDFF !default;
$indigo-300: #AA8AFF !default;
$indigo-400: #8457FF !default;
$indigo-500: #4100F0 !default;
$indigo-600: #4100F0 !default;
$indigo-700: #3300BD !default;
$indigo-800: #25008A !default;
$indigo-900: #5B2FE2 !default;

$pink-100: #FFF0FE !default;
$pink-200: #FFBDFA !default;
$pink-300: #FF8AF7 !default;
$pink-400: #FF57F3 !default;
$pink-500: #FF24F0 !default;
$pink-600: #F000E0 !default;
$pink-700: #BD00B0 !default;
$pink-800: #8A0081 !default;
$pink-900: #570051 !default;

$red-100: #FFF5F3 !default;
$red-200: #FFCBC0 !default;
$red-300: #FFA18D !default;
$red-400: #FF765A !default;
$red-500: #FF4C27 !default;
$red-600: #F32A00 !default;
$red-700: #C02100 !default;
$red-800: #8D1800 !default;
$red-900: #5A0F00 !default;

$orange-100: #FFF9F0 !default;
$orange-200: #FFE4BD !default;
$orange-300: #FFD08A !default;
$orange-400: #FFBB57 !default;
$orange-500: #FFA724 !default;
$orange-600: #F09000 !default;
$orange-700: #BD7100 !default;
$orange-800: #8A5300 !default;
$orange-900: #ffc398 !default;

$green-100: #EEFEF7 !default;
$green-200: #BEFBE0 !default;
$green-300: #8DF9C9 !default;
$green-400: #5DF6B3 !default;
$green-500: #2DF39C !default;
$green-600: #0DE083 !default;
$green-700: #0AB067 !default;
$green-800: #07804B !default;
$green-900: #17C9A9 !default;

$container-max-widths: (
    sm: 640px,
    md: 768px,
    lg: 1024px,
    xl: 1146px
) !default;

$font-family-base: "Readex Pro",ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !default;
$font-size-base: 1rem !default;
$font-size-sm: 0.875rem !default;
$font-size-lg: 1.125rem !default;
$font-weight-bold: 700 !default;

$small-font-size: .875em !default;
$line-height-lg: 2rem !default;

$headings-font-family: "Readex Pro",ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.25 !default;

$h1-font-size: 8.875rem !default;
$h2-font-size: 6rem !default;
$h3-font-size: 2.5rem !default;
$h4-font-size: 1.875rem !default;
$h5-font-size: 1.25rem !default;
$h6-font-size: 1rem !default;

$display-font-family: Prata,ui-serif,Georgia,Cambria,"Times New Roman",Times,serif !default;
$display-font-weight: 400 !default;
$display-font-sizes: (1: 6rem,2: 5rem,3: 4rem,4: 3.5rem,5: 2.5rem) !default;
$display-line-height: 1.25 !default;

$lead-font-size: 1rem !default;
$lead-font-weight: 400 !default;

$box-shadow: 10px 24px 56px rgba(0, 0, 0, 0.08) !default;
$box-shadow-sm: 10px 24px 56px rgba(0, 0, 0, 0.04) !default;
$box-shadow-lg: 18px 24px 104px rgba(0, 0, 0, 0.04) !default;
$box-shadow-inset: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06) !default;

$spacers: (
    0: 0px,
    1: 0.25rem,
    2: 0.5rem,
    3: 0.75rem,
    4: 1rem,
    5: 1.25rem,
    6: 1.5rem,
    7: 1.75rem,
    8: 2rem,
    9: 2.25rem,
    10: 2.5rem,
    11: 2.75rem,
    12: 3rem,
    14: 3.5rem,
    16: 4rem,
    20: 5rem,
    24: 6rem,
    28: 7rem,
    32: 8rem,
    36: 9rem,
    40: 10rem,
    44: 11rem,
    48: 12rem,
    52: 13rem,
    56: 14rem,
    60: 15rem,
    64: 16rem,
    72: 18rem,
    80: 20rem,
    96: 24rem
) !default;

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null) !default;

$link-decoration: none !default;
$link-hover-decoration: underline !default;

$badge-font-size: 0.75rem !default;
$badge-font-weight: 400 !default;
$badge-color: #101010 !default;
$badge-padding-y: 0.375rem !default;
$badge-padding-x: 0.5rem !default;
$badge-border-radius: 0px !default;
$badge-text-transform: null !default;

$btn-border-radius: 0.25rem !default;

$input-btn-padding-y: 0.75rem !default;
$input-btn-padding-x: 1.75rem !default;
$input-btn-font-size: 1rem !default;
$input-btn-line-height: 2 !default;
$input-btn-padding-y-sm: 0.375rem !default;
$input-btn-padding-x-sm: 1.25rem !default;
$input-btn-font-size-sm: 0.75rem !default;
$input-btn-padding-y-lg: 1.25rem !default;
$input-btn-padding-x-lg: 2.25rem !default;
$input-btn-font-size-lg: 1.125rem !default;

$btn-font-weight: 500 !default;

$input-color: #232126 !default;
$input-border-color: #D2D2D2 !default;
$input-placeholder-color: #9B96A4 !default;
$input-padding-y: 0.75rem !default;
$input-padding-x: 2.5rem !default;
$input-border-radius: 0.5rem !default;

$nav-link-font-size: 0.875rem !default;
$nav-link-font-weight: 400 !default;
$navbar-padding-y: 1.5rem !default;
$navbar-padding-x: 1rem !default;
$navbar-nav-link-padding-x: 3rem !default;
$navbar-light-color: #000 !default;
$navbar-light-hover-color: #101010 !default;
$navbar-light-active-color: #101010 !default;
$navbar-light-disabled-color: #9B96A4 !default;
$navbar-dark-color: #fff !default;
$navbar-dark-hover-color: #DFDFDF !default;
$navbar-dark-active-color: #DFDFDF !default;
$navbar-dark-disabled-color: #9B96A4 !default;

$alert-color: null !default;
$alert-font-size: null !default;
$alert-border-width: 0 !default;
$alert-border-radius: 0 !default;

$alert-border-top-width: $alert-border-width !default;
$alert-border-right-width: $alert-border-width !default;
$alert-border-bottom-width: $alert-border-width !default;
$alert-border-left-width: $alert-border-width !default;

$alert-border-top-right-radius: $alert-border-radius !default;
$alert-border-top-left-radius: $alert-border-radius !default;
$alert-border-bottom-right-radius: $alert-border-radius !default;
$alert-border-bottom-left-radius: $alert-border-radius !default;


